import { Inertia } from '@inertiajs/inertia'
import { InertiaApp } from "@inertiajs/inertia-react";
import { InertiaProgress } from "@inertiajs/progress";
import React from "react";
import { render } from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

InertiaProgress.init();

const app = document.getElementById("app");

render(
    <>
        <InertiaApp
            initialPage={JSON.parse(app.dataset.page)}
            resolveComponent={(name) =>
                import(`./pages/${name}.tsx`).then((module) => module.default)
            }
        />
        <ToastContainer />
    </>,
    app
);

Inertia.on("navigate", (event) => {
    window.dataLayer.push({
        url: event.detail.page.url,
        event: "pageview",
    });
});
